<template>
  <v-row>
    <v-col
      cols="12"
      md="11"
    >
      <v-text-field
        v-model="contact.name"
        :label="$t('lbl.name')"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <!--<v-col
      cols="12"
      sm="5"
    >
      <v-select
        v-model="contact.role_id"
        :items="rols"
        item-text="name"
        item-value="id"
        :label="$t('menu.roles')"
        outlined
        clearable
        dense
        hide-details
      ></v-select>
    </v-col>-->
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteContactsAfiliados(pos)"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>

    <v-col
      cols="12"
      md="11"
    >
      <v-select
        v-model="contact.products"
        :items="productsFree"
        :label="$t('lbl.products')"
        outlined
        dense
        hide-details
        item-text="name"
        item-value="id"
        multiple
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.products') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contact: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    productItem: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
    }
  },
  computed: {
    productsFree() {
      const arr = []
      this.products.forEach(element => {
        if (this.productItem.includes(element.id)) {
          arr.push(element)
        }
      })

      return arr
    },
  },
  created() {
    this.getProducts()
    this.getRols()
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
        })
    },
    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
