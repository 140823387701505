<template>
  <fragment>
    <v-col
      cols="12"
      md="5"
      offset-md="1"
    >
      <v-text-field
        v-model="telefono.telefono"
        :label="$t('lbl.telefono')"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="telefono.ext"
        label="Ext."
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="1"
    >
      <v-tooltip
        v-if="pos === 0"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="addTelefonoContactsAfiliados(posContact)"
          >
            <v-icon small>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      v-if="contactsAfiliados[posContact].telefonos.length > 1"
      cols="12"
      sm="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            class="ml-2"
            v-on="on"
            @click="deleteTelefonoContactsAfiliados({ pos: pos, index: posContact })"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    telefono: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posContact: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      rols: [],
      products: [],
    }
  },
  computed: {
    ...mapState({
      contactsAfiliados: state => state.app.contactsAfiliados,
    }),
  },
  methods: {
    ...mapMutations(['addTelefonoContactsAfiliados', 'deleteTelefonoContactsAfiliados']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
